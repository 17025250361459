.MedMalpracticeIns .react-select__control {
  background-color: none;
  border-color: #d1d5db;
}
.MedMalpracticeIns .react-select__control :hover {
  border-color: #d1d5db;
}
.MedMalpracticeIns .react-select__multi-value {
  color: #ffffff;
  background-color: #414bbe;
  border-radius: 1.5rem;
  height: 1.5em;
}

.MedMalpracticeIns .react-select__multi-value__label {
  color: #ffffff;
  display: flex;
  align-items: end;
}
