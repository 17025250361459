.Daily_Visits{
    height:30rem;
    width:55.5rem;
    background-color:white ;
}
.Total_Users{
    width: 23.5rem;
}
.User_Per_State{
    height:22.5rem;
    width:39.5rem;
    background-color: white;
}