/* 


.DatePicker_Input .react-date-picker__inputGroup {
  padding-left: 0.675rem;
}

.DatePicker_Input .react-date-picker__inputGroup__input[name="year"] {
  width: 36px !important;

}

.DatePicker_Input .react-date-picker__inputGroup__input[name="month"] {
  width: 18px !important;
}

.DatePicker_Input .react-date-picker__inputGroup__input[name="day"] {
  width: 18px !important;
}

.DatePicker_Input .react-date-picker__button:enabled {
  cursor: pointer;
  outline: none;
  color: white;
}

.DatePicker_Input .react-date-picker__button {
  background: transparent;
  padding: 4px 6px;
  color: white;
}
.DatePicker_Input .react-date-picker__inputGroup__leadingZero {
  margin-top: -2px;
}
.DatePicker_Input .react-date-picker__button svg {
  stroke: currentColor;
  color: #9298a9;
  width: 19px;
  height: 19px;
}

.DatePicker_MainWrapper  {
    font-size: 0.875rem;
    line-height: 1rem;
} */
.DatePicker_Input .react-date-picker__wrapper {
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid lightgray;
  line-height: 2.3;
  margin-top:-2.5px;
  color: black;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}
.DatePicker_Input.react-date-picker {
  width: 100%;
}
.DatePicker_Input .react-date-picker__button svg {
  stroke: currentColor;
  color: #9298a9;
  width: 19px;
  height: 19px;
}
.DatePicker_Input .react-date-picker__inputGroup__leadingZero {
  margin-top: -2px;
}